import React from 'react'
import {Form, FormGroup, Label, Input } from 'reactstrap';
import Button from './btn'

export default () => (
  <Form name="Contactformulier" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
    <input type="hidden" name="form-name" value="Contactformulier"/>
    <FormGroup>
        <Label for="naam">Naam</Label>
        <Input type="text" name="naam" id="name_field" placeholder="Uw naam" />
    </FormGroup>
      <FormGroup>
        <Label for="email">E-mail</Label>
        <Input type="text" name="email" id="email_field" placeholder="E-mail adres" />
      </FormGroup>
      <FormGroup>
        <Label for="telefoon">Telefoonnummer</Label>
        <Input type="text" name="telefoon" id="telephone_field" placeholder="Telefoonnummer" />
      </FormGroup>
      <FormGroup>
        <Label for="budget">Budget</Label>
        <Input type="number" name="budget" id="budget_field"/>
      </FormGroup>
      <FormGroup>
        <Label for="bericht">Bericht</Label>
        <Input type="textarea" name="bericht" id="message_field" placeholder="Typ hier uw bericht" />
      </FormGroup>
    <Button type="submit">Verzenden</Button>
  </Form>
)
